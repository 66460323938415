<template>
    <Modal class="content-form" @close="closeForm()" :opened="opened">
        <template v-slot:header>
            {{ $t('cms.edit_block_properties')}}
        </template>
        
        <template v-slot:body>
            <BaseForm 
                :form_class="'form_small'" 
                @closeForm="closeForm()" 
                :cancelButton="''"
                :deleteButton="false" >
                
            </BaseForm>
        </template>
    </Modal>
</template>

<script>
import BaseForm from '@common/components/crud/BaseForm.vue'
import Modal from "@common/components/Modal";
import useFormData from '@common/composable/BaseFormData';
import { useI18n } from 'vue-i18n';
import { watchEffect, computed } from 'vue';
import { useStore } from 'vuex';

export default {
    name: "ContentForm",
    components: {
        BaseForm,
        Modal
    },
    emits: ['close',"updateValues"],
    props: {
        opened: Boolean,
        baseData: {
            type: Object,
            default: () => null
        },
    },
    setup(props,context){       
        const i18n = useI18n();          
        const { data,setRows,formInit,formActions,code } = useFormData();
        
        const store = useStore();

        const rows = computed(() => {
            let _rows = [
                {
                    labels: [i18n.t('controls.name')],
                    items: [
                        {
                            title: i18n.t('controls.name'),
                            type: 'text',
                            key: "name",
                            class: "w-xl",
                        }
                    ]
                },
                {
                    labels: [i18n.t('controls.description')],
                    items: [
                        {
                            title: i18n.t('controls.description'),
                            type: 'textarea',
                            key: "description",
                            class: "w-xl",
                        }
                    ]
                },
            ];

            let _classes = store.getters['project/getClassList']('block');
            if(_classes){
                let _classOptions = [];
                for(let _class of _classes){
                    _classOptions.push({
                        title: _class.title,
                        code: _class.value,
                    })
                }
                _rows.push({
                    labels: [i18n.t('cms.html_class')],
                    items: [
                        {
                            title: i18n.t('cms.html_class'),
                            type: 'select',
                            key: "class",
                            class: "w-xl",
                            options: () => _classOptions
                        }
                    ]
                })
            }
            return _rows;
        })
        
        const closeForm = () => {
            context.emit('close');
        }
        
        const afterSubmit =  () => {
                context.emit('updateValues',data.value);
                closeForm()
            };
        
        watchEffect(() => {
            if(props.baseData === null || !props.opened){
                return;
            }
            formInit();
            data.value = Object.assign({},props.baseData);
            code.value = props.baseData.code;
            setRows(rows.value);
            formActions.value.afterSubmit = afterSubmit;
        });

        
        return {
            closeForm,
            data,
        }

    }
}
</script>

<style scoped>
.set_homepage{
    margin-bottom: 1rem;
    float: right;
}

</style>